
import {
    atom,
} from 'recoil';

export const userState = atom({
    key: 'userState',
    default: null,
});

export const userSubscriptionState = atom({
    key: 'userSubscriptionState',
    default: null,
});

export const userCharacterChats = atom({
    key: 'userCharacterChats',
    default: [],
});

export const subscriptionPlansState = atom({
    key: 'subscriptionPlansState',
    default: [],
});

export const systemConstantsState = atom({
    key: 'systemConstants',
    default: {},
});