import React from 'react';
import { FeatureLockOverlay } from './FeatureLockOverlay';

export const LockedChatOverlay = () =>  {
  return (
    <>
        <FeatureLockOverlay>
            <div className="flex justify-center items-center flex-col overlay-content w-full">
                <h1 className='text-4xl font-bold mb-4'>Please login</h1>
                <p className='text-md'>Seems like you are not logged in. Please Signup/ Login to start chatting.</p>
            </div>
        </FeatureLockOverlay>
    </>
  )
}