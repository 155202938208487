import React from 'react'
import lodash from "lodash";
import { Avatar, AvatarImage } from '../../components/ui/avatar'
import { Button } from '../../components/ui/button'
import { Info, Trash2, Loader2 } from 'lucide-react';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/store';

export const TopbarIcons = [{ icon: Info }];


export default function ChatTopbar({ character, loading, handleCharacterChatClear }) {

  const [user, setUserState] = useRecoilState(userState);

  const handleUpdateCharacterDescription = () => { }

  return (
    <div className="w-full h-20 flex p-4 justify-between items-center border-b">
      <div className="flex items-center gap-2">
        <Avatar className="flex justify-center items-center">
          <AvatarImage
            src={lodash.get(character, ['images', 0], '')}
            alt={character.fullname}
            width={6}
            height={6}
            className="w-10 h-10 "
          />
        </Avatar>
        <div className="flex flex-col text-left">
          <span className="font-medium">{character.fullname}</span>
          {lodash.get(user, ['role']) === 'admin' ? <div className='w-2/4 max-w-2/4'>
            {lodash.get(character, ['images', 0], '') ? <span className="text-xs overflow-hidden whitespace-nowrap overflow-ellipsis">{lodash.get(character, ['description'], '')}</span> : null}
          </div> : null}
        </div>
      </div>

      {lodash.get(user, ['role']) === 'admin' ? <div className='flex align-center'>
        <Button
          className='mr-2'
          onClick={handleCharacterChatClear}
        >
          {loading === 'CLEARING_CHAT' ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : <Trash2 />}
        </Button>

      </div> : null}
    </div>
  )
}