import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import lodash from 'lodash';
import {
    useRecoilState
} from 'recoil';
// Components
import { Button } from '../components/ui/button';
import LoginForm from '../components/LoginForm';
// Services and Utils
import { logError, setToken, setUser } from '../services/utils';
import {
    checkUserSessionStatus,
    getUserAccessToken,
} from '../services/api';
import { userState, userSubscriptionState } from '../store/store';
import { logout } from '../lib/utils/auth_utils';

export const LoginPage = () => {

    const history = useHistory();
    const [user, setUserState] = useRecoilState(userState);
    const [subscriptionState, setSubscriptionState] = useRecoilState(userSubscriptionState);


    const [state, setState] = useState({
        userLoggedIn: false,
        userName: null,
    });

    const authenticate = () => {
        let tempauthToken = (window.location.search.match(/authToken=([^&]+)/) || [])[1];
        window.history.pushState('object', document.title, '/explore');

        if (tempauthToken) {
            // Try to get an access token from the server
            getAccessToken(tempauthToken);
        } else {
            // Check user is logged in
            checkUserSessionStatus();
        }
    };

    const getAccessToken = async (authToken) => {
        try {
            const response = await getUserAccessToken({ authToken });

            // Permanent Access Token for exchaning the over the APIs
            const accessToken = lodash.get(response, ['data', 'access_token'], null);
            const user = lodash.get(response, ['data', 'user'], null);

            // Setting the token to the storage
            setToken(accessToken);
            setUser(user);

            // Re authenticating the user this is an additional step can be removed in the future
            await checkSessionStatus(accessToken);
        } catch (error) {

        }

    };

    const checkSessionStatus = async () => {
        try {
            const response = await checkUserSessionStatus();

            const userLoggedIn = lodash.get(response, ['data', 'userLoggedIn'], false);
            const loggedInUser = lodash.get(response, ['data', 'user'], false);

            setUserState(loggedInUser);
            setSubscriptionState(lodash.get(loggedInUser, 'subscription_details', null))

            if (userLoggedIn) {
                // Redirect the user to the Home page
                history.push('/explore')
            }
        } catch (error) {
            // Log the error here, e.g. send it to a logging service
            logError(error)
        }

    };

    useEffect(() => {
        authenticate();
    }, []);

    return (
        <section id="page-container">
            {state.userLoggedIn ? (
                <div>
                    <div>You are now logged in!</div>
                    <div>
                        <Button onClick={logout}>Logout</Button>
                    </div>
                </div>
            ) : (
                <LoginForm />
            )}
        </section>
    );
}
