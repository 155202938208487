
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../components/ui/button';
import { ModeToggle } from "../components/ui/mode-toggler";
import { UserNav } from "../components/navbar/user-nav";
import { SubscriptionDialog } from "../components/SubscriptionDialog";
import { CreateOrUpdateNewCharacterDialog } from "./CreateOrUpdateNewCharacterDialog";

export default function WithSubnavigation() {

  const history = useHistory();


  return (
    <div className="border-b foreground">
      <div className="flex h-16 items-center px-4">
        <Button variant='link' className='brand-text-bg text-4xl font-bold' onClick={() => history.push('/explore')}>Quorra</Button>
        <div className="ml-auto flex items-center space-x-4">
          <CreateOrUpdateNewCharacterDialog />
          <SubscriptionDialog />
          <ModeToggle />
          <UserNav />
        </div>
      </div>
    </div>
  )
}