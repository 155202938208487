import {
    useRecoilState,
} from 'recoil';
import lodash from 'lodash';

import { Button } from "./ui/button"
import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from "./ui/dialog"
import { CreateAndUpdateCharacterForm } from "./characters/CreateAndUpdateCharacterForm"

import { userState } from "../store/store";


export function CreateOrUpdateNewCharacterDialog({ character }) {
    // const [user] = useRecoilState(userState);
    return null

    // if (lodash.get(user, ['role']) !== 'admin') {
    //     return null
    // }


    // return (
    //     <Dialog className="w-screen max-w-4xl">
    //         <DialogTrigger asChild>
    //             <Button className="">
    //                 Create Character
    //             </Button>
    //         </DialogTrigger>
    //         <DialogContent className="">
    //             <CreateAndUpdateCharacterForm character={character} />
    //         </DialogContent>
    //     </Dialog>
    // )
}
