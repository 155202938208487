
import lodash from "lodash";

import { useHistory } from "react-router-dom";
import { cn } from "../../lib/utils";

import { Image } from "../ui/image";

export function CharacterCard({
  character,
  aspectRatio = "portrait",
  width,
  height,
  className,
  ...props
}) {
  const history = useHistory();

  const handleChatBtnClick = (id) => {
    history.push(`/explore/chat/${id}`);
  };

  return (
    <div
      className={cn(
        "space-y-2 rounded-md cursor-pointer relative",
        className
      )}
      {...props}
      onClick={() => handleChatBtnClick(lodash.get(character, ["id"]))}
    >
      <Image
        src={lodash.get(character, ["images", 0], "")}
        alt={lodash.get(character, ["fullname"], "")}
        width={width}
        height={height}
        className={cn(
          "h-auto w-auto transition-all hover:scale-105 object-cover",
          aspectRatio === "portrait" ? "aspect-[3/4]" : "aspect-square"
        )}
      />


      <div
        className="space-y-1 text-sm text-left px-4 py-2 absolute bg-gradient-to-t from-black via-transparent w-full h-full"
        style={{ zIndex: 10, bottom: 0 }}
      >
        <div className='text-lg font-bold leading-none absolute w-full flex justify-between items-center p-2'
          style={{ zIndex: 10, bottom: 0, left: 0 }}>
          <div className='flex flex-col'>

            <p
              className="text-lg font-bold leading-none"
            >
              {lodash.get(character, ["fullname"], "")}
            </p>
            <p
              className="text-xs"
            >
              {lodash.get(character, ["meta", "profession"], "")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}