import React from 'react'
import lodash from 'lodash'
import { useHistory } from "react-router-dom";

import { Button } from "../components/ui/button";
import { Avatar, AvatarImage, AvatarFallback } from "../components/ui/avatar"



function ConversationsList(props) {
    const { userChats } = props;
    const history = useHistory();

    return (
        <>
            {(userChats || []).map((chat, index) => (
                <Button key={index} variant="ghost" className="w-full flex justify-start items-center text-md py-6" onClick={() => history.push(`/explore/chat/${lodash.get(chat, ['character', 'id'])}`)}>
                    <Avatar>
                        <AvatarImage className={'flex h-8 w-8 shrink-0 overflow-hidden rounded-full'} src={lodash.get(chat, ["character", "images", 0])} alt="@shadcn" />
                        <AvatarFallback>CN</AvatarFallback>
                    </Avatar>
                    {lodash.get(chat, ['character', 'fullname'])}
                </Button>
            ))}
        </>
    )
}

export default ConversationsList