import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js';

// UI components
import { TabsTrigger, TabsList, TabsContent, Tabs } from "../components/ui/tabs"
import { CardTitle, CardDescription, CardHeader, CardContent, CardFooter, Card } from "../components/ui/card"
import { Button } from "../components/ui/button"
import { createCheckoutSession, getSubscriptions } from '../services/api';
import { logError } from '../services/utils';


export const SubscriptionsPage = () => {
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        (async () => {
            // Fetching the plans from the server
            try {
                const response = await getSubscriptions();
                const subscriptions = response.data.data;

                // Filtering out the free plans
                const paidSubscriptions = subscriptions.filter(subscription => subscription.price > 0);

                setSubscriptions(paidSubscriptions);
            } catch (error) {
                logError(error)
            }
        })()
    }, []);

    const handleSubscribe = async (plan, frequency) => {
        try {
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
            const body = {
                plan_id: plan.plan_id,
                plan_frequency: frequency,
            }

            const response = await createCheckoutSession({ body });
            const session = response.data;

            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });
            if (result.error) {
                console.log(result.error.message)
            }
        } catch (error) {
            logError(error)
        }
    }
    return (
        <div className="h-screen flex flex-col items-center justify-center">
            <div>
                <p className='text-7xl mb-2'>Choose your plan</p>
                <p className='text-xl mb-4'>100% anonymous. You can cancel anytime.</p>
            </div>
            <Tabs className=" w-full flex flex-col justify-center items-center" defaultValue="monthly">
                <TabsList className="flex space-x-2">
                    <TabsTrigger value="monthly">Monthly</TabsTrigger>
                    <TabsTrigger value="yearly">Yearly</TabsTrigger>
                </TabsList>
                <TabsContent value="monthly">
                    <div className="flex  gap-4">
                        {subscriptions.map((subscription) => (
                            <Card className="p-8">
                                <CardHeader className="pb-4">
                                    <CardTitle>{subscription.label}</CardTitle>
                                    <CardDescription>For small projects</CardDescription>
                                    <div className="flex items-center space-x-2">
                                        <span className="text-3xl font-semibold">${subscription.price / 100}</span>
                                        <span className="text-sm text-gray-500 dark:text-gray-400">/ month</span>
                                    </div>
                                </CardHeader>
                                <CardContent>
                                    <ul className="list-disc ml-6 space-y-2">
                                        <li>Unlimited projects</li>
                                        <li>3 team members</li>
                                        <li>5GB file storage</li>
                                    </ul>
                                </CardContent>
                                <CardFooter>
                                    <Button variant="outline">Select</Button>
                                </CardFooter>
                            </Card>
                        ))}
                    </div>
                </TabsContent>
                <TabsContent value="yearly">
                    <div className="flex gap-4">
                        {subscriptions.map((subscription) => (
                            <Card className="p-8">
                                <CardHeader className="pb-4">
                                    <CardTitle>{subscription.label}</CardTitle>
                                    <CardDescription>For small projects</CardDescription>
                                    <div className="flex items-center space-x-2">
                                        <span className="text-3xl font-semibold">${subscription.price / 100}</span>
                                        <span className="text-sm text-gray-500 dark:text-gray-400">/ month</span>
                                    </div>
                                </CardHeader>
                                <CardContent>
                                    <ul className="list-disc ml-6 space-y-2">
                                        <li>Unlimited projects</li>
                                        <li>3 team members</li>
                                        <li>5GB file storage</li>
                                    </ul>
                                </CardContent>
                                <CardFooter>
                                    <Button variant="outline">Select</Button>
                                </CardFooter>
                            </Card>
                        ))}
                    </div>
                </TabsContent>
            </Tabs>
        </div>
    )
}