import { Button } from "../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../components/ui/dialog"

import { UpgradeCards } from "./subscriptions/UpgradeCards"

export function SubscriptionDialog() {

  return null;

  // return (
  //   <Dialog className="w-screen max-w-4xl">
  //     <DialogTrigger asChild>
  //       <Button className="">
  //         Upgrade
  //       </Button>
  //     </DialogTrigger>
  //     <DialogContent className="">
  //       <UpgradeCards />
  //     </DialogContent>
  //   </Dialog>
  // )
}
