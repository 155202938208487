import { useHistory } from "react-router-dom";
import { Loader2, GalleryVerticalEnd, GalleryHorizontal } from "lucide-react"

import { cn } from "../../lib/utils";
import { Button } from "../../components/ui/button";
import { Separator } from "../../components/ui/separator"
import { SidebarFooter } from "../SidebarFooter";
import { ScrollArea, ScrollBar } from "../../components/ui/scroll-area"


import ConversationsList from "../ConversationsList";
import { StartChattingCard } from "../StartChattingCard";


export function Sidebar({ className, userChats, loadingUserChats }) {
  const history = useHistory();

  const currentPath = history.location.pathname;

  return (
    <div className={cn("pb-12", className)}>
      <div className="space-y-4 py-4 text-left px-4 flex flex-col justify-between" style={{ height: "calc(100vh - 72px)" }}>
        <div className="">
          <div className="py-2">
            <h2 className="mb-2 text-xl font-semibold tracking-tight">
              Discover
            </h2>
            <div className="space-y-1">
              <Button variant={currentPath === '/explore' ? "secondary" : "tertiary"} className="w-full justify-start text-md" onClick={() => history.push('/explore')}>
                <GalleryHorizontal className="mr-2 h-4 w-4" />
                Explore
              </Button>
              <Button variant={currentPath === '/explore/threads' ? "secondary" : "tertiary"} className="w-full justify-start text-md" onClick={() => history.push('/explore/threads')}>
                <GalleryVerticalEnd className="mr-2 h-4 w-4" />
                Threads
              </Button>
            </div>
          </div>
          <Separator className="my-2" />

          <div className="py-2">
            <h2 className="mb-2 text-xl font-semibold tracking-tight flex items-center">
              Your chats
              {loadingUserChats ? <Loader2 className="ml-4 h-4 w-4 animate-spin" /> : null}
            </h2>
            <div className="space-y-1">
              <ScrollArea>
                {userChats.length === 0 ? (
                  <StartChattingCard />
                ) : (
                  <ConversationsList userChats={userChats} />
                )}


                <ScrollBar orientation="vertical" />
              </ScrollArea>
            </div>
          </div>
        </div>

        <SidebarFooter />
      </div>
    </div>
  )
}