import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";


function RootPage() {
  const history = useHistory();

    useEffect(() => {
        history.push('/explore')
    }, []);

  return (
    <div></div>
  )
}

export default RootPage