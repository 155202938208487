import { cn } from "../lib/utils";
import { CharacterImageGallery } from "./CharacterImageGallery";
import { CharacterDetailsSection } from "./CharacterDetailsSection";

export function CharacterDetailsSidebar({ className, character }) {

    return (
        <div className={cn("pb-12", className)}>
            <div className=" relative space-y-4 py-4 text-left px-4 flex flex-col justify-start" style={{ height: "calc(100vh - 104px)" }}>
                <CharacterImageGallery character={character} />
                <CharacterDetailsSection character={character} />
            </div>
        </div>
    )
}