import './App.css';

import lodash from 'lodash';
import {
  useRecoilState
} from 'recoil';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import DashboardWrapper from './views/DashboardWrapper';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { ThemeProvider } from "../src/lib/theme-provider"
import RootPage from './views/RootPage';
import { checkUserSessionStatus } from './services/api';
import { logError } from './services/utils';
import { userState, userSubscriptionState, subscriptionPlansState, systemConstantsState } from './store/store';
import { getSubscriptions, getSystemConstants } from './services/api';
import { setUser } from './services/utils';
import { PoliciesPage } from './views/policies/PoliciesPage';
import { LoginPage } from './views/LoginPage';
import { SubscriptionsPage } from './views/SubscriptionsPage';


import { _initMonitoringAndLogging } from './services/utils';
import { AdminDashboard } from './views/admin/AdminDashboard';


function App() {
  const history = useHistory();
  const [user, setUserState] = useRecoilState(userState);
  const [subscriptionState, setSubscriptionState] = useRecoilState(userSubscriptionState);
  const [subscriptionPlans, setSubscriptionPlans] = useRecoilState(subscriptionPlansState);
  const [systemConstants, setSystemConnstants] = useRecoilState(systemConstantsState);

  const _checkSessionStatus = async () => {
    try {
      const response = await checkUserSessionStatus();

      const userLoggedIn = lodash.get(response, ['data', 'userLoggedIn'], false);
      const loggedInUser = lodash.get(response, ['data', 'user'], false);

      // Setting the user in the local storage for persistence
      setUser(loggedInUser);

      setUserState(loggedInUser);
      setSubscriptionState(lodash.get(loggedInUser, 'subscription_details', null))

      if (userLoggedIn) {
        // Redirect the user to the Home page
        if (history) history.push('/explore')
      }
    } catch (error) {
      // Log the error here, e.g. send it to a logging service
      logError(error)
    }
  };

  const _getSubscriptionPlans = async () => {
    // Fetching the plans from the server
    try {
      const response = await getSubscriptions();
      const subscriptions = response.data.data;

      // Filtering out the free plans
      const paidSubscriptions = subscriptions.filter(subscription => subscription.price > 0);

      setSubscriptionPlans(paidSubscriptions);
    } catch (error) {
      logError(error)
    }
  }

  const _getSystemConstants = async () => {
    // Fetching System constants
    try {
      const response = await getSystemConstants({ constants: "SYSTEM_PROMPT,STATIC_STRINGS,FEATURE_FLAGS,POLICIES" });
      const constants = lodash.get(response, ['data', 'data']);

      setSystemConnstants(constants);
    } catch (error) {
      logError(error)
    }
  }



  useEffect(() => {
    _checkSessionStatus();
    _getSubscriptionPlans();
    _getSystemConstants();

    // Initialising the monitoring and analytics
    _initMonitoringAndLogging();
  }, [])

  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/">
              <RootPage />
            </Route>
            <Route path="/explore">
              <DashboardWrapper
                accounts={[]}
                defaultLayout={[265, 655]}
                defaultCollapsed={false}
                navCollapsedSize={4} />
            </Route>
            <Route exact path="/auth">
              <LoginPage />
            </Route>
            <Route exact path="/subscriptions">
              <SubscriptionsPage />
            </Route>

            <Route exact path="/policies">
              <PoliciesPage />
            </Route>

            <Route exact path="/admin">
              <AdminDashboard />
            </Route>
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
