import React from 'react';
import lodash from 'lodash';
import { Sparkles } from "lucide-react";
import { useRecoilState } from 'recoil';
import { systemConstantsState } from '../store/store';

export const BannerJumbotron = () => {
  const [systemConstants] = useRecoilState(systemConstantsState);

  const JUMBOTRON_DESCRIPTION = lodash.get(systemConstants, ['STATIC_STRINGS', 'value', 'JUMBOTRON_DESCRIPTION'])

  if (!JUMBOTRON_DESCRIPTION) { return null; }

  return (
    <div className='jumbotron-gradient rounded-lg p-8 text-left my-2'>
      <h1 className='text-4xl font-bold mb-4 flex items-center'><span className='flex items-center '><Sparkles className='mr-2 flex text-yellow-300' /> Beta Release!&nbsp;</span> {lodash.get(JUMBOTRON_DESCRIPTION, ['title'])}</h1>
      <p className='text-md'>{lodash.get(JUMBOTRON_DESCRIPTION, ['description'])}</p>
    </div>
  )
}