import React from 'react'

import { Card } from '../components/ui/card'
import { Button } from '../components/ui/button'

export const StartChattingCard = () => {
    return (
        <>
            <Card className="w-full p-4 jumbotron-gradient">
                <h2 className="text-lg font-bold mb-2">Meet Your AI Chat Companion</h2>
                <p className="text-sm">Experience the future of conversational AI! Chat with intelligent AI companions designed to engage, entertain, and connect with you on a whole new level. Join our beta and start chatting today!</p>
                <Button variant="secondary" className="mt-4 text-lg">
                    Start Chatting
                </Button>
            </Card>
        </>
    )
}