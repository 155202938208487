import axios from "axios";

import { getToken } from '../services/utils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";

export const OAUTH_PRODUCER_LOGIN_REDIRECT_ENDPOINT = `${API_BASE_URL}/auth/login-redirect`;

// Authentication APIs
export const getUserAccessToken = (params) => {
    const { authToken } = params;
    const headers = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + authToken,
        },
        credentials: 'include',
    };
    return axios.get(`${API_BASE_URL}/auth/login`, headers);
}

export const checkUserSessionStatus = (params) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.get(`${API_BASE_URL}/auth/user-session-status`, headers);

}

export const logoutUser = async () => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        credentials: 'include',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.get(`${API_BASE_URL}/auth/logout`, headers);
}


export const getCharacters = () => {

    return axios.get(`${API_BASE_URL}/characters`);
}

export const getCharacter = (params) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    const { characterId } = params;
    return axios.get(`${API_BASE_URL}/characters/${characterId}`, headers);
}


// Chat APIs
export const connectCharacterChat = (params) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    const { payload } = params;
    return axios.post(`${API_BASE_URL}/chats/connect`, payload, headers);
}

export const postCharacterChat = (params) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    const { payload } = params;
    // return axios.post(`${API_BASE_URL}/chats/completion`, payload, headers);
    return axios.post(`${API_BASE_URL}/chats/togetherai/completion`, payload, headers);
}

export const testTogetherAICompletion = (params) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };

    const { payload } = params;

    return axios.post(`${API_BASE_URL}/chats/togetherai/completion`, payload, headers);
}

export const getCharacterChats = (params) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    const { characterId } = params;
    return axios.get(`${API_BASE_URL}/chats/${characterId}`, headers);
}

export const getUserChats = (params) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    return axios.get(`${API_BASE_URL}/chats/user`, headers);
}


export const clearCharacterChats = (params) => {
    const token = getToken();

    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    const { characterId } = params;
    return axios.post(`${API_BASE_URL}/chats/${characterId}/clear`, {}, headers);
}


// Subscription APIs
export const getSubscriptions = () => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.get(`${API_BASE_URL}/subscription_plans`, headers);
}

// Payment APIs
export const createCheckoutSession = (params) => {
    const token = getToken();
    if (!token) { return; }

    const { body } = params;
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.post(`${API_BASE_URL}/payments/create-checkout-session`, body, headers);
}

// System constant APIs
export const getSystemConstants = (params) => {
    const { constants } = params;
    return axios.get(`${API_BASE_URL}/system_constants?constants=${constants}`);
}

// Feedback APIs
export const postFeedback = (params) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }

    const { payload } = params

    return axios.post(`${API_BASE_URL}/feedbacks`, payload, headers);
}