import { MessageSquareMore } from 'lucide-react';
import { Button } from "./ui/button";
import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from "./ui/dialog";
import { FeedbackForm } from "./feedback/FeedbackForm";

export function FeedbackDialog() {
    return (
        <Dialog className="w-screen max-w-4xl">
            <DialogTrigger asChild>
                <Button variant="secondary" className="w-full text-md">
                    <MessageSquareMore className='mr-2' />
                    Feedback
                </Button>
            </DialogTrigger>
            <DialogContent className="">
                <FeedbackForm />
            </DialogContent>
        </Dialog>
    )
}
