import * as React from "react"

import { cn } from "../../lib/utils"

const Link = React.forwardRef(({ className, src, alt, type, ...props }, ref) => {
  return (
    (<a
      src={src}
      alt={alt}
      className={cn(
        className
      )}
      ref={ref}
      {...props} />)
  );
})
Link.displayName = "Link"

export { Link }
