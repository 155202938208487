import React from 'react';
import lodash from 'lodash';

import { Separator } from "../components/ui/separator";

export const CharacterDetailsSection = ({ character }) => {
    return (
        <div className='border rounded-lg p-4 card-gradient'>
            < p className='text-2xl font-bold py-2' > {lodash.get(character, ['fullname'])}</p >
            <Separator className="my-2" />
            <div className='flex flex-row flex-wrap'>
                <div className='flex flex-col p-2 w-2/4'>
                    <p className='font-bold'>Nationality</p>
                    <p className=''>{lodash.get(character, ['meta', 'ethnicity'])}</p>
                </div>
                <div className='flex flex-col p-2 w-2/4'>
                    <p className='font-bold'>Age</p>
                    <p className=''>{lodash.get(character, ['meta', 'age'])}</p>
                </div>
                <div className='flex flex-col p-2 w-2/4'>
                    <p className='font-bold'>Weight-Height</p>
                    <p className=''>{lodash.get(character, ['meta', 'body', 'weight'])}-{lodash.get(character, ['meta', 'body', 'height'])}</p>
                </div>
                <div className='flex flex-col p-2 w-2/4'>
                    <p className='font-bold'>Figure</p>
                    <p className=''>{lodash.get(character, ['meta', 'body', 'figure'])}</p>
                </div>
                <div className='flex flex-col p-2 w-2/4'>
                    <p className='font-bold'>Features</p>
                    <p className=''>{lodash.get(character, ['meta', 'body', 'features'])}</p>
                </div>
            </div>
        </div >
    )
}