import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from '../../services/utils';

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
    const accessToken = getToken();
    return (
        <Route
            {...rest}
            render={(props) =>
                !!accessToken ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/explore" />
                )
            }
        />
    );
};

export default ProtectedRoute;