
import React, { useState, useEffect } from "react";
import lodash from "lodash";
import { useParams } from "react-router-dom";
// Custom components
import { CharacterChat } from "../components/CharacterChat";
import { CharacterDetailsSidebar } from "../components/CharacterDetailsSidebar";
// Services
import { getCharacter } from "../services/api";
import { logError } from "../services/utils";

function ChatsWrapper() {
    const { characterId } = useParams();

    const [character, setCharacter] = useState({});
    const [collapseDetails, setCollapseDetails] = useState(false);


    const fetchCharacter = async () => {
        try {
            const response = await getCharacter({ characterId });
            const character = lodash.get(response, ['data', 'data']);
            setCharacter(character);
        } catch (error) {
            logError(error);
        }
    };

    useEffect(() => {
        fetchCharacter();
    }, [characterId]);

    return (
        <>
            <div className="grid lg:grid-cols-8">
                <div className={`main-area-gradient ${collapseDetails ? 'col-span-6' : 'col-span-6'}`}>
                    <CharacterChat character={character} />
                </div>
                <div className={`${collapseDetails ? '' : 'col-span-2'}`}>
                    <CharacterDetailsSidebar className="hidden lg:block" character={character} />
                </div>
            </div>
        </>
    )
}

export default ChatsWrapper