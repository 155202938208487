import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
// Custom components
import { Button } from '../components/ui/button';
import { Image } from '../components/ui/image';
import { CarouselItem, CarouselContent, Carousel } from "../components/ui/carousel";

const CharacterSliderCount = ({ current, total }) => {
  return (
    <div className="absolute top-4 right-4 bg-white/50 dark:bg-gray-950/50 p-2 rounded-sm shadow-md">
      <span className="text-gray-950 dark:text-gray-50">{current} / {total}</span>
    </div>
  );

}

export const CharacterImageGallery = ({ character }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  console.log('Character Image Gallery', currentSlide, character);

  const handlePreviousSlide = () => {
    if (currentSlide === 0) return;
    setCurrentSlide((prevSlide) => prevSlide - 1);
  };

  const handleNextSlide = () => {
    if (currentSlide === lodash.get(character, ['images'], []).length - 1) return;
    setCurrentSlide((prevSlide) => prevSlide + 1);
  };

  useEffect(() => {
    setCurrentSlide(0);
  }, [character.id]);

  return (
    <Carousel className="w-full h-full relative">
      <CarouselContent className='h-full'>
        <CarouselItem style={{ maxHeight: "calc(100vh - 96px)" }}>
          <Image
            alt={lodash.get(character, ['images', currentSlide], [])}
            src={lodash.get(character, ['images', currentSlide], [])}
            className="w-full h-full object-cover"
          />
        </CarouselItem>
        <CharacterSliderCount current={currentSlide + 1} total={lodash.get(character, ['images'], []).length} />
      </CarouselContent>
      <Button
        className="absolute left-4 top-1/2 -translate-y-1/2 rounded-full bg-white/50 p-2 shadow-md hover:bg-white/80 focus:outline-none focus:ring-2 focus:ring-gray-950 dark:bg-gray-950/50 dark:hover:bg-gray-950/80 dark:focus:ring-gray-300"
        disabled={currentSlide === 0}
        onClick={handlePreviousSlide}
      >
        <ChevronLeftIcon className="h-6 w-6" />
        <span className="sr-only">Previous</span>
      </Button>
      <Button
        className="absolute right-4 top-1/2 -translate-y-1/2 rounded-full bg-white/50 p-2 shadow-md hover:bg-white/80 focus:outline-none focus:ring-2 focus:ring-gray-950 dark:bg-gray-950/50 dark:hover:bg-gray-950/80 dark:focus:ring-gray-300"
        disabled={currentSlide === lodash.get(character, 'images', []).length - 1}
        onClick={handleNextSlide}
      >
        <ChevronRightIcon className="h-6 w-6" />
        <span className="sr-only">Next</span>
      </Button>
    </Carousel>
  );
};

function ChevronLeftIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m15 18-6-6 6-6" />
    </svg>
  )
}


function ChevronRightIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  )
}