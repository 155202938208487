import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import {
    useRecoilState,
} from 'recoil';

// Components
import Navbar from "../components/Navbar";
import ChatsWrapper from './ChatsWrapper';
import ExploreView from './explore/ExploreView';

import ProtectedRoute from '../components/auth/ProtectedRoute';
import { Sidebar } from '../components/explore/sidebar';
import { logError } from '../services/utils';
import { getUserChats, getCharacters } from '../services/api';
import { userCharacterChats } from '../store/store';
import { ThreadsWrapper } from './ThreadsWrapper';

function DashboardWrapper() {

    // Existing chats started by the user
    // const [userChats, setUserChats] = useState([]);
    // Available characters
    const [characters, setCharacters] = useState([]);
    const [loadingCharacters, setLoadingCharacters] = useState(false);

    const [userChats, setUserChats] = useRecoilState(userCharacterChats); // Setting this to the recoil state as this will be used in multiple components
    const [loadingUserChats, setLoadinguserChats] = useState(false);

    const _getCharacters = async () => {
        try {
            setLoadingCharacters(true);
            const rv = await getCharacters();
            const characters = lodash.get(rv, ['data', 'data'], []);
            setCharacters(characters);
            return characters;
        } catch (error) {
            logError(error);
            return [];
        } finally {
            setLoadingCharacters(false);
        }
    }

    const _getUserChats = async (characters) => {
        try {
            setLoadinguserChats(true);
            const rv = await getUserChats();

            // Get the character details by characterId from the chats and characters
            const chats = lodash.get(rv, ['data'], []).map(chat => {
                const character = (characters || []).find(c => c.id === chat.character_id);
                return {
                    ...chat,
                    character,
                }
            });
            setUserChats(chats);
        } catch (error) {
            logError(error);
        } finally {
            setLoadinguserChats(false);
        }
    }

    // Getting the users active chats
    useEffect(() => {
        (async () => {
            const characters = await _getCharacters();
            await _getUserChats(characters);
        })()
    }, []);

    return (
        <>
            <div className="">
                <Navbar />
                <div className="border-t">
                    <div className="bg-background">
                        <div className="grid lg:grid-cols-5">
                            <Sidebar className="hidden lg:block" loadingUserChats={loadingUserChats} userChats={userChats} />
                            <div className="col-span-3 lg:col-span-4 lg:border-l main-area-gradient">
                                <div className="h-full px-2 py-2">
                                    <Switch>
                                        <Route exact path="/explore">
                                            <ExploreView characters={characters} loadingCharacters={loadingCharacters} />
                                        </Route>
                                        <ProtectedRoute path="/explore/chat/:characterId">
                                            <ChatsWrapper />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/explore/threads">
                                            <ThreadsWrapper />
                                        </ProtectedRoute>
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardWrapper