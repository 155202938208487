import mixpanel from 'mixpanel-browser';
import * as Sentry from "@sentry/react";

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || 'f4ab4e5e7c8b29b6928b691a5c1380c4';
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || "https://432303be3caef3ed0eb985c301b30222@o4507125208186880.ingest.us.sentry.io/4507232823083008";

export const logError = (error) => {
    // Log the error here, e.g. send it to a logging service
    console.error('Error:', error);

    Sentry.captureException(error);
};

export const logDebug = (message) => {
    // Log the debug message here, e.g. send it to a logging service
    console.log('Debug:', message);
};

export const setToken = (token) => {
    // Encrypt the token using btoa before saving it to local storage
    const encryptedToken = btoa(token);
    return localStorage.setItem('accessToken', encryptedToken);
}

export const getToken = () => {
    // Retrieve the encrypted token from local storage
    const encryptedToken = localStorage.getItem('accessToken');
    if (encryptedToken === null) return null;
    // Decrypt the token using atob before returning it
    return atob(encryptedToken);
}

export const removeToken = () => {
    return localStorage.removeItem('accessToken');
}

export const setUser = (user) => {
    // Encrypt the user object using btoa before saving it to local storage
    const encryptedUser = btoa(JSON.stringify(user));
    if (encryptedUser === null) return null;

    return localStorage.setItem('user', encryptedUser);
};

export const getUser = () => {
    // Retrieve the encrypted user object from local storage
    const encryptedUser = localStorage.getItem('user');
    if (!encryptedUser) return null;

    // Decrypt the user object using atob before returning it
    return JSON.parse(atob(encryptedUser));
}

export const removeUser = () => {
    return localStorage.removeItem('user');
}

export const _initMonitoringAndLogging = () => {
    // Initializing Mixpanel
    mixpanel.init(MIXPANEL_TOKEN, { debug: true, track_pageview: true, persistence: 'localStorage' });

    // Init Sentry


    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            // Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

export const trackAnalyticsEvent = (eventName, eventProperties) => {
    mixpanel.track(eventName, eventProperties);
}