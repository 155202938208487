import React, { useState, useEffect, useRef } from "react";
import lodash from "lodash";
import { useParams } from "react-router-dom";
import { Loader2 } from "lucide-react"
import {
    useRecoilState,
} from 'recoil';

import { Input } from "../components/ui/input"
import { Button } from "../components/ui/button"
import { Avatar, AvatarImage, AvatarFallback } from "../components/ui/avatar"
import ChatTopbar from "./chat/ChatTopbar";

import { postCharacterChat, clearCharacterChats, connectCharacterChat, testTogetherAICompletion } from "../services/api";
import { logError } from "../services/utils";
import { LockedChatOverlay } from "./LockedChatOverlay";
import { userState, userSubscriptionState } from "../store/store";
import { SubscriptionDialog } from "./SubscriptionDialog";
import { Separator } from "@radix-ui/react-dropdown-menu";
import ErrorBoundary from "./ui/ErrorBoundary";

import { trackAnalyticsEvent } from "../services/utils";

const ChatInput = ({ user, messages, inputMessage, handleInputMessage, handleSendMessage, handleKeyPress, loadingMessageResponse, subscriptionState }) => {

    // If the messages are exhausted by the user then show them a banner saying they have exhausted the messages they need to upgrade
    if ((lodash.get(subscriptionState, ['features', 'messages', 'exhausted']) >= lodash.get(subscriptionState, ['features', 'messages', 'limit']) && lodash.get(user, ['role']) !== 'admin')) {
        return (
            <div className='my-2'>
                <Separator />
                <div className='rounded-md m-2 px-2 py-1 text-left flex justify-between items-center dark:bg-gray-800'>
                    <p className='text-md'>Oh! ho! looks like you have exhausted your message limits. Please upgrade to continue chatting.</p>
                    <SubscriptionDialog />
                </div>
            </div>
        );
    }

    // If the user has exhausted the character chat limits then show them a banner saying they have exhausted the character chat limits and if there was not previous chats that were started with the character
    if (((lodash.get(subscriptionState, ['features', 'character_chats', 'exhausted']) >= lodash.get(subscriptionState, ['features', 'character_chats', 'limit'])) && messages.length === 0) && lodash.get(user, ['role']) !== 'admin') {
        return (
            <div className='my-2'>
                <Separator />
                <div className='rounded-md m-2 px-2 py-1 text-left flex justify-between items-center dark:bg-gray-800'>
                    <p className='text-md'>Oh! ho! looks like you have exhausted your character chat limits. Please upgrade to continue chatting.</p>
                    <SubscriptionDialog />
                </div>
            </div>
        );
    }

    return (
        <div className="flex items-center space-x-2 border-t p-2">
            <Input className="flex-1 min-w-0 text-lg p-4 mx-2"
                placeholder={(messages || []).length === 0 ? "Send your first message..." : `Type a message...`}
                type="text"
                disabled={loadingMessageResponse}
                value={inputMessage}
                onChange={handleInputMessage}
                onKeyPress={handleKeyPress}
            />
            <Button className="h-10" disabled={loadingMessageResponse} onClick={() => handleSendMessage(inputMessage)}>
                {loadingMessageResponse ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                Send
            </Button>
        </div>
    );
}

export const CharacterChat = (props) => {
    const { character } = props;
    const { characterId } = useParams();

    const [loadingMessageResponse, setLoadingMessageResponse] = useState(false);
    const [loading, setLoading] = useState('');
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("")

    const [user] = useRecoilState(userState);
    const [subscriptionState, setSubscriptionState] = useRecoilState(userSubscriptionState);

    const _connectCharacterChat = async () => {
        try {
            const response = await connectCharacterChat({ payload: { character_id: characterId, message: 'Hi!' } });
            const characterChats = lodash.get(response, ['data', 'messages']);
            const subscritpionState = lodash.get(response, ['data', 'subscription_state']);

            setMessages(characterChats);
            setSubscriptionState(subscritpionState);

            // Track analytics to capture character chat connected
            trackAnalyticsEvent("CHARACTER_CHAT_CONNECTED", { character_id: characterId, user_id: lodash.get(user, ['id']) });
        } catch (error) {
            logError(error);
        }
    }

    useEffect(() => {

        // Connecting the user to the chat, only connect if we know the user, because we only support Guest mode till character list
        // To talk to the character your must login
        if (user) {
            _connectCharacterChat();
        }
    }, [characterId]);

    useEffect(() => {
        if (lodash.get(subscriptionState, ['features', 'messages']) === "connected") {
            _connectCharacterChat();
        }
    }, [subscriptionState])

    const handleInputMessage = (e) => {
        setInputMessage(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSendMessage(e.target.value);
        }
    }

    const handleSendMessage = async (inputMessage) => {
        try {
            if (!inputMessage.length) {
                return;
            }

            setLoadingMessageResponse(true);

            setMessages(prevState => [...prevState, { role: "user", content: inputMessage }])

            const response = await postCharacterChat({
                payload: { message: inputMessage, messages, character_id: characterId },
            });
            const responseMessage = lodash.get(response, ["data", "messages"], []);
            const subscriptionState = lodash.get(response, ["data", "subscription_state"]);

            setSubscriptionState(subscriptionState);

            if (responseMessage.length) {
                setMessages(responseMessage);
                setInputMessage("");
            }

        } catch (error) {
            logError(error);
        } finally {
            setLoadingMessageResponse(false);
        }
    };

    const chatContainerRef = useRef(null);

    useEffect(() => {
        // Auto scroll to bottom when new message is added
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }, [messages]);

    const handleCharacterChatClear = async (characterId) => {
        try {
            setLoading('CLEARING_CHAT');
            await clearCharacterChats({ characterId });

            setMessages([]);
            setLoading('');
        } catch (error) {
            setLoading('CLEARING_CHAT');
            logError(error);
        }
    }

    const _testTogetherAICompletion = async (characterId) => {
        try {
            setLoading('CLEARING_CHAT');
            await testTogetherAICompletion({ characterId });

            setMessages([]);
            setLoading('');
        } catch (error) {
            setLoading('CLEARING_CHAT');
            logError(error);
        }
    }

    return (
        <ErrorBoundary>
            <div className="border rounded-md my-2 chat-section relative" >
                {!user ? <LockedChatOverlay /> : null}
                <ChatTopbar character={character} handleCharacterChatClear={() => handleCharacterChatClear(character.id)} loading={loading} />
                <div className="flex flex-col text-left p-4 overflow-y-auto chat-list" ref={chatContainerRef}>
                    {(messages || []).map((message, index) => (
                        <div key={index}>
                            {message.role === "user" ? (
                                <div className="flex items-start justify-end space-x-2 self-end m-2">
                                    <div className="bg-gray-100 rounded-md p-3 text-md dark:bg-gray-400 text-black max-w-fit w-4/5">
                                        {message.content}
                                    </div>
                                    <Avatar>
                                        <AvatarImage src={"https://github.com/shadcn.png"} alt="@shadcn" />
                                        <AvatarFallback>CN</AvatarFallback>
                                    </Avatar>
                                </div>
                            ) : (
                                <div className="flex items-start space-x-2 m-2">
                                    <Avatar>
                                        <AvatarImage src={lodash.get(character, ['images', 0], '')} alt="@shadcn" />
                                        <AvatarFallback>CN</AvatarFallback>
                                    </Avatar>
                                    <div className="bg-gray-100 rounded-md p-3 text-md dark:bg-gray-800 max-w-fit w-4/5">
                                        {message.content}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}

                    {loadingMessageResponse ? (
                        <div className="flex items-start space-x-2 m-2">
                            <Avatar>
                                <AvatarImage src={lodash.get(character, ['images', 0], '')} alt="@shadcn" />
                                <AvatarFallback>CN</AvatarFallback>
                            </Avatar>
                            <div className="bg-gray-100 rounded-md p-3 text-sm dark:bg-gray-800 max-w-fit w-4/5">
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            </div>
                        </div>
                    ) : null}

                </div>
                <ChatInput user={user} messages={messages} inputMessage={inputMessage} handleInputMessage={handleInputMessage} handleSendMessage={handleSendMessage} handleKeyPress={handleKeyPress} loadingMessageResponse={loadingMessageResponse} subscriptionState={subscriptionState} />
            </div>
        </ErrorBoundary>
    );
}

export default CharacterChat;
