import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { Link } from '../../components/ui/link';
import { TermsOfService } from './TermsOfService';
import { SupportPolicies } from './SupportPolicies';

export const PoliciesPage = () => {
    return (
        <div>
            <ul>
                <li>
                    <Link src="/policies/terms-of-service">Terms of service</Link>
                </li>
                <li>
                    <Link to="/policies/support">Support</Link>
                </li>
                <li>
                    <Link to="/">Policy 3</Link>
                </li>
            </ul>
            <Switch>
                <Route path="/terms-of-service">
                    <TermsOfService />
                </Route>
                <Route path="/policies/support">
                    <SupportPolicies />
                </Route>
            </Switch>
        </div>
    )
}

export default PoliciesPage