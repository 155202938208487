import React from "react";

export const ThreadsWrapper = () => {

    return (
        <>
            <div className='flex w-full h-full justify-center items-center'>
                {/* This will have the Chats list and the single chat with the character */}
                <p className="text-4xl text-left">Coming Soon!</p>
            </div>
        </>


    )
}