import React from 'react'

import { Button } from '../components/ui/button'
import { Separator } from "../components/ui/separator"
import { Twitter, Youtube, MessageCircle } from 'lucide-react';

import { FeedbackDialog } from './FeedbackDialog';

export const SidebarFooter = () => {
  return (
    <div className="flex flex-col items-center justify-end space-y-4 p-4">
      <Separator className="" />
      {/* <div className="flex space-x-4">
        <Button variant="icon" className="text-blue-500 border border-white rounded-full w-12 h-12">
          <Twitter />
        </Button>
        <Button variant="icon" className="text-red-500 border border-white rounded-full w-12 h-12">
          <Youtube />
        </Button>
        <Button variant="icon" className="text-indigo-500 border border-white rounded-full w-12 h-12">
          <MessageCircle />
        </Button>
      </div> */}
      <div className="flex flex-col w-full space-y-2">
        <FeedbackDialog />
        {/* <Button variant="secondary" className="w-full">
          Leave Comment
        </Button> */}
      </div>
    </div>
  );
}