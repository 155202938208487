
import React from 'react';
import { Loader } from 'lucide-react';

import { Separator } from "../../components/ui/separator"
import {
  Tabs,
  TabsContent,
} from "../../components/ui/tabs";
import { ScrollArea, ScrollBar } from "../../components/ui/scroll-area";

import { CharacterCard } from "../../components/explore/CharacterCard";
import ErrorBoundary from '../../components//ui/ErrorBoundary';
import { BannerJumbotron } from "../../components/BannerJumbotron";

const CharacterGrid = ({ characters }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
      {characters.map((character) => (
        <CharacterCard
          key={character.id}
          character={character}
          className="w-full"
          aspectRatio="portrait"
          width={284}
          height={312}
        />
      ))}
    </div>
  )
}

const CharacterLoadingScreen = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <Loader />
      <p className='text-xl'>Loading Characters...</p>
    </div>
  )
}

export default function ExploreView({ characters, loadingCharacters }) {
  if (!characters) {
    // Show loader instead for showing that the characters are loading
    return null;
  }

  return (
    <>
      <ErrorBoundary>
        <div
          style={{ height: "calc(100vh - 84px)", maxHeight: "calc(100vh - 84px)", overflowY: "auto" }}
        >
          <ScrollArea className="">
            <BannerJumbotron />
            <Tabs defaultValue="characters" className="space-y-6 ">
              <div className="space-between flex items-center">
              </div>
              <TabsContent
                value="characters"
                className="border-none p-0 outline-none"
              >
                <div className="flex items-center justify-between">
                  <div className="space-y-1">
                    <h2 className="text-2xl font-semibold tracking-tight text-left">
                      Trending
                    </h2>
                    <p className="text-sm text-muted-foreground">
                      Top picks for you. Updated daily.
                    </p>
                  </div>
                </div>
                <Separator className="my-4" />
                <div className="relative">
                  {loadingCharacters ? <CharacterLoadingScreen /> : <CharacterGrid characters={characters} />}
                </div>
              </TabsContent>
            </Tabs>
            <ScrollBar orientation="vertical" />
          </ScrollArea>
          {/* <Footer /> */}
        </div>
      </ErrorBoundary>
    </>
  )
}